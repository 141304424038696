<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>챗봇 검색실패 내역조회</h1>
            <div class="is-right">
            </div>
        </div>

        <!--조회-->
        <div class="jh-search-form">
            <table>
                <colgroup>
                    <col width="55px">
                    <col width="250px">
                    <col width="70px">
                    <col width="150px">
                    <col>
                </colgroup>
                <tr>
                    <th><label class="is-required">조회기간</label></th>
                    <td>
                        <div class="jh-cols">                        
                            <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                                maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                              </template>
                              <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="startDate"></v-date-picker>
                            </v-menu>
                            <span class="jh-unit">~</span>                       
                            <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                                maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                              </template>
                              <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="endDate"></v-date-picker>
                            </v-menu>
                        </div>
                    </td>
                    <th><label>키워드</label></th>
                    <td><input type="text" class="jh-form"></td>
                    <td class="has-search"><v-btn class="jh-btn is-search">조회</v-btn></td>
                </tr>
            </table>
        </div>
        <!--//조회-->

        <!--리스트-->
        <div class="jh-form-wrap">
            <div class="jh-ui-header">
                <h2>챗봇 검색실패 내역 목록</h2>
                <div class="is-right">
                    <v-btn class="jh-btn is-light">엑셀다운로드</v-btn>
                </div>
            </div>

            <data-tables
            dataTableClass=""
            :data-table-options="dataTableOptions"
            @click:row="onClickRow"
            :paginationOptions="paginationOptions"
            >
                <template v-slot:item.colm10>
                    <button type="button" class="jh-btn is-icon"><i class="jh-icon-circle-play is-blue"></i></button>
                </template>
            </data-tables>
        </div>
        <!--//리스트-->
    </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";
  
export default {
    name: "MENU_E020402", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
        dataTables
    },
    data() {
        return {
            menuStartDate: false,
            menuEndDate: false,
            from: moment().format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
            dates: [
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            ],
            headers: [
                { text: 'NO', value: 'index', align: 'center', width: '60px', sortable: false },
                { text: '검색 키워드', value: 'colm01', align: 'center', },
                { text: '검색 건수', value: 'colm02', align: 'center', },
                { text: '비중(%)', value: 'colm03', align: 'center', },
            ],
            items: [
                { },
            ],

            dataTableOptions: {
                fixedHeader: true,
                hideDefaultFooter: true,
                height: '600px',
                itemKey: 'index',
                itemsPerPage: 50,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                singleSelect: true
            },
            paginationOptions: {
                totalVisible: 10
            },
        }
    },
    computed: {
        rowNum() {
            return this.items.map(
                (items, index) => ({
                ...items,
                index: index + 1
            }))
        },
    },
    methods: {
        fromOnInput: function() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
                // 종료일자보다 이후를 입력했으면 종료일자로 변경
                if (moment(this.from).isAfter(this.dates[1])) {
                    this.$nextTick(() => {
                        this.from = this.dates[1];
                    });
                }

                this.$nextTick(() => {
                    this.dates[0] = this.from;
                    // datepick 다시 랜더링
                    this.menuStartDate = false;
                    this.menuStartDate = true;
                });
            }
        },
        startDate(e) {
            this.from = e;
        },
        toOnInput: function() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
                // 시작일자보다 이전을 입력했으면 시작일자로 변경
                if (moment(this.to).isBefore(this.dates[0])) {
                    this.$nextTick(() => {
                        this.to = this.dates[0];
                    });
                }

                this.$nextTick(() => {
                    this.dates[1] = this.to;
                    // datepick 다시 랜더링
                    this.menuEndDate = false;
                    this.menuEndDate = true;
                });
            }
        },
        endDate(e) {
            this.to = e;
        },
        onClickRow: function (item, row) { 
            if(this.selectedId===item.index){
                row.select(false);
                this.selectedId=item.name;
            }else{
                row.select(true);
                this.selectedId=item.index;
            }
        },
        openSave(){
            this.$openWindow('T101201','T101201','700','480');
        },
        showDialog(type) {
            //팝업 파라미터 설정
            this.popupParams;
            this[`dialog${type}`] = true;
        },
        hideDialog(type) {
            this[`dialog${type}`] = false;
        },
        submitDialog(type){
            console.log('완료');
            this[`dialog${type}`] = false
        },
        
    },
    created() { 
        this.dataTableOptions.headers = this.headers;
        this.dataTableOptions.items = this.rowNum;
    }
};
</script>

<style></style>  